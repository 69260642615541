<div class="wrapper flex column">
  <app-header title="{{ 'USER_PROFILE.HEADER.TITLE' | translate }}"></app-header>
  <div class="content flex column grow1" *ngIf="!loading">
    <div class="auto-save-box flex vcenter" [class.show]="saving">
      <img src="assets/shared/global-images/icons/dot-green.svg" />
      <span>{{ "AUTOSAVE" | translate }}</span>
    </div>

    <div class="user-management-box flex">
      <button mat-button class="avatar flex hcenter vcenter">
        {{ userInitials }}
      </button>

      <form class="user-form flex column grow1" [formGroup]="userForm">
        <payee-form-field>
          <div payee-label>{{ "USER_PROFILE.LAST_NAME_LABEL" | translate }}</div>
          <input payee-input formControlName="lastName" />
          <mat-error
            payee-error
            *ngIf="lastName.hasError('required') && lastName.dirty"
          >
            {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </mat-error>
        </payee-form-field>

        <payee-form-field>
          <div payee-label>{{ "USER_PROFILE.FIRST_NAME_LABEL" | translate }}</div>
          <input payee-input formControlName="firstName" />
          <mat-error
            payee-error
            *ngIf="firstName.hasError('required') && firstName.dirty"
          >
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
          </mat-error>
        </payee-form-field>

        <payee-form-field>
          <div payee-label class="payee-label-top">{{ "USER_PROFILE.EMAIL_LABEL" | translate }}</div>
          <input readonly payee-input formControlName="email" />
        </payee-form-field>
      </form>

      <div class="user-management-buttons-box flex column">
        <button
          [disabled]="!canChangePassword"
          mat-button
          class="change-pwd-button"
          (click)="changePassword()"
        >
          <div class="pwd-button-content">
            <div class="icon-wrapper">
              <payee-icon [name]="'lock-blue'"></payee-icon>
            </div>
            <span class="button-text">{{ "USER_PROFILE.PASSWORD_RESET" | translate }}</span>
          </div>
        </button>
        <!-- <button
          *ngIf="!has2FA"
          mat-button
          class="enable-2fa-button"
          (click)="enable2FA()"
        >
          <span>2FA bekapcsolása</span>
        </button> -->
      </div>
    </div>

    <div class="clients-title">{{ "USER_PROFILE.CLIENTS_LIST.TITLE" | translate }}</div>

    <div class="clients-list">
      <ng-container *ngFor="let client of clients; let i = index">
        <payee-horizontal-line *ngIf="i > 0"></payee-horizontal-line>
        <div class="client-name flex vcenter">{{ client.name }}</div>
        <div class="client-type flex vcenter"></div>
        <button
          mat-button
          class="client-settings"
          (click)="navigateToClientProfile(client)"
        >
          <div class="client-settings-content flex vcenter hcenter">
            <span>{{ "USER_PROFILE.CLIENTS_LIST.EDIT" | translate }}</span>
            <img src="assets/shared/global-images/icons/gear.svg" />
          </div>
        </button>
      </ng-container>
    </div>
  </div>
</div>
